import React, { Component } from 'react';
import $ from "jquery";
import gif from '../assets/twisted1.gif';

$(function () {
	const scrollEffect = $('.conT3Main');
	const in17 = $('#in17');
	const in18 = $('#in18');
	const in19 = $('#in19');
 


	$(window).on('scroll', function () {
		const containerTop = scrollEffect.offset().top;
		const containerBottom = containerTop + scrollEffect.outerHeight();
		const windowTop = $(window).scrollTop();
		const windowBottom = windowTop + $(window).height();

		if (windowBottom > containerTop) {
			in17.addClass('in17');
			in18.addClass('in18');
			in19.addClass('in19');
 

		} else {
			in17.removeClass('in17');
			in18.removeClass('in18');
			in19.removeClass('in19');
	 
		}


		console.log("containerTop :" + containerTop);
		console.log("containerBottom :" + containerBottom);
		console.log("windowTop :" + windowTop);
		console.log("windowBottom :" + windowBottom);


	});
});


class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqBg">

        <div class="accordin reveal">

          <div class="conT3Main">
            <div class="conT3">
              <span id="in17">F</span>
              <span id="in18">A</span>
              <span id="in19">Q</span>
            </div>
          </div>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>What is Cryptoland?</summary>
            <div class="faq__content">
              <p>Cryptoland is an amazing planet where all NFTs live together and building web3 space.</p>
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>Who are y00punks?</summary>
            <div class="faq__content">
              <p>One fateful day, during a festive gathering in the heart of CryptoLand, a mysterious
                anomaly occurred. A powerful surge of energy emanated from an ancient digital artifact,
                causing a cosmic fusion between the Cryptopunks and the Y00ts.</p>

              <p>The two communities found
                themselves transformed into an extraordinary new collection: the YooPunks.</p>
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>What are y00punks NFTs?</summary>
            <div class="faq__content">
              <p>y00punks is a generative collection of 2000 unique pfps with proof of ownership stored on
                the Ethereum Blockchain.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>How do I mint my y00punk NFT?</summary>
            <div class="faq__content">
              <p>Go to y00punks official website(y00punks.com), click mint, approve the wallet transaction.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>Can I buy y00punks in the secondary market?</summary>
            <div class="faq__content">
              <p>Yes. You can buy on Opensea.</p>
            </div>
          </details>

        </div>

        <img class="nft2" src={gif} />

      </div>


    )
  }
}

export default FAQ;

