import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/hel-4.gif';
import radio from '../assets/radio.png';
import $ from "jquery";

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

$(function () {
    const scrollEffect = $('.conTInMain');
    const in1 = $('#in1');
    const in2 = $('#in2');
    const in3 = $('#in3');
    const in4 = $('#in4');
    const in5 = $('#in5');
    const in6 = $('#in6');
    const in7 = $('#in7');
    const in8 = $('#in8');

  
    $(window).on('scroll', function () {
      const containerTop = scrollEffect.offset().top;
      const containerBottom = containerTop + scrollEffect.outerHeight();
      const windowTop = $(window).scrollTop();
      const windowBottom = windowTop + $(window).height();
  
      if (windowBottom > containerTop) {
        in1.addClass('in1');
        in2.addClass('in2');
        in3.addClass('in3');
        in4.addClass('in4');
        in5.addClass('in5');
        in6.addClass('in6');
        in7.addClass('in7');
        in8.addClass('in8');
      } else {
        in1.removeClass('in1');
        in2.removeClass('in2');
        in3.removeClass('in3');
        in4.removeClass('in4');
        in5.removeClass('in5');
        in6.removeClass('in6');
        in7.removeClass('in7');
        in8.removeClass('in8');
      }
  
  
      console.log("containerTop :" + containerTop);
      console.log("containerBottom :" + containerBottom);
      console.log("windowTop :" + windowTop);
      console.log("windowBottom :" + windowBottom);
  
  
    });
  });

class Introduce extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="aboutIn">
 
                    <div class="conTInMain">
                        <div class="conTIn">
                        <span id="in1">y</span>
                        <span id="in2">0</span>
                        <span id="in3">0</span>
                        <span id="in4">p</span>
                        <span id="in5">u</span>
                        <span id="in6">n</span>
                        <span id="in7">k</span>
                        <span id="in8">s</span>
                    </div>
                    </div>

                    <div class="storyConIn">
                        <p>Introducing y00punks, an exciting NFT project at the forefront of the Web3 revolution.</p>

                        <p>y00punks combines the charm of aesthetically-inspired art with the limitless potential of
                            blockchain technology, creating a unique and captivating digital experience for the community.</p>
                        <p>The y00punks brand produces community, digital collectibles, content, opportunities for massive
                            rewards and pfp3.
                        </p>

                        <p>It's a busy place but you'll be relax with new favorite y00punks family!</p>

                    </div>
                </div>
                <div class="mainImgs">
                    <img class="nft" src={nft} />
                    <img class="radio" src={radio} />
                </div>
            </div>
        )
    }
}

export default Introduce;

