import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import $ from "jquery";
import gif from '../assets/mushroom-land1.gif'

const Stry = () => {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, [])
}

$(function () {
  const scrollEffect = $('.scroll-effect-relic');

  $(window).on('scroll', function () {
    const containerTop = scrollEffect.offset().top;
    const containerBottom = containerTop + scrollEffect.outerHeight();
    const windowTop = $(window).scrollTop();
    const windowBottom = windowTop + $(window).height();

    if (windowBottom > containerTop) {
      scrollEffect.addClass('animate3');
    } else {
      scrollEffect.removeClass('animate3');
    }


    console.log("containerTop :" + containerTop);
    console.log("containerBottom :" + containerBottom);
    console.log("windowTop :" + windowTop);
    console.log("windowBottom :" + windowBottom);


  });
});

class Buy extends Component {

  render() {
    return (

      <div class="boxWrapBuy">
        <div class="about">

          <div class="storyCon2">

            <div class="scroll-effect-container" data-aos="fade-right" data-aos-duration="1000">
              <div class="scroll-effect-relic">
                <div class="storyCon2Div">Mint,</div>
              </div>
            </div>
            
            <div class="scroll-effect-container">
              <div class="scroll-effect-relic" data-aos="fade-right" data-aos-duration="1000">
                <div class="storyCon2Div">Trade,</div>
              </div>
            </div>

            <div class="scroll-effect-container">
              <div class="scroll-effect-relic" data-aos="fade-right" data-aos-duration="1000">
                <div class="storyCon2Div">Unleash</div>
              </div>
            </div>

            <div class="storyCon2Div2">the Digital Renaissance with NFTs!</div>


          </div>

        </div>

        <img class="nft2" src={gif} />

      </div>
    )
  }
}

export default Buy;

